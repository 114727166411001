var loading = false;
var body = jQuery("html, body");
var goToTeam;
var goToPost;

jQuery(document).ready(function(){


            jQuery('.row_bot_plus_proy').on("click",".mas_productos",function(ev){    
                ev.preventDefault(); 			 

                jQuery('.mas_productos').remove();

                jQuery('.img_loading').css('display','block');



                //console.log(MyAjax.url+" "+MyAjax.nonce);

                var elID = jQuery(this).data("id") ;
               /* var taxTipo  = jQuery(this).data("taxTipo") ; 
                var termTipo = jQuery(this).data("termTipo") ;
                var termFirma = jQuery(this).data("termFirma") ;
                var taxFirma = jQuery(this).data("taxFirma") ; */

                var taxTipo  = jQuery(this).attr("data-taxTipo") ; 
                var termTipo = jQuery(this).attr("data-termTipo") ;
                var termFirma = jQuery(this).attr("data-termFirma") ;
                var taxFirma = jQuery(this).attr("data-taxFirma") ; 

                //console.log("ID" +  elID +"TAX TIPO " + taxTipo+" TERMTIPO " +termTipo +" termFIRMA "+ termFirma+" taxFirma " +taxFirma );
                /*
data-termTipo="<?php echo $termTipo ?>" data-taxTipo="<?php echo $taxTipo ?>" data-termFirma="<?php echo $taxTipo ?>" data-taxFirma="<?php echo $taxFirma ?>" 
                */

                              //
     
          		jQuery.post(MyAjax.url, {nonce : MyAjax.nonce, action : 'cargarMas' ,id : elID , taxTipoAjax : taxTipo, termTipoAjax :termTipo, termFirmaAjax:termFirma, taxFirmaAjax : taxFirma }, function(response) {

                    //console.log("RESPONSE<br>");

                    //console.log(response);

              		var nuResponse='<div class="proy_loaded"> '+response+'</div>';
                    jQuery('.more_proys').html(nuResponse); 


                    reScroll();
                   // var bLazy = new Blazy();

                    jQuery('.img_loading').css('display','none');


                    var arrSwiper;
                    jQuery('.slickr-single-more').each(function(){

                        var idSwip ='#'+ $(this).attr('id');

                        

                  var tmpSwip =  $(idSwip).slick({
                      dots: false,
                      infinite: true,
                      speed: 1000,
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      centerMode: true,
                      variableWidth: true,
                      autoplay: true,
                      autoplaySpeed: 2500,
                      arrows:false,
                      pauseOnFocus:false,
                      initialSlide:2
                    });       
 

                    $(idSwip).on('init', function(event, slick, direction){
                        console.log('edge was hit');
                      });   



                  jQuery(idSwip).parent().find('.slick_der').on('click', function(ev){

                    ev.preventDefault();
                   // $(idSwip).slick('slickNext');

                    //alert($(this).parents('.cont_slick').find('.slick_slider_no_home').attr('class')); 
                     $(this).parents('.cont_slick').find('.slick_slider_no_home').slick('slickNext');

                    
                   
                  });

                

                  jQuery(idSwip).parent().find('.slick_left').on('click', function(ev){

                    ev.preventDefault();
                   // alert("izq");
                    //$(idSwip).slick('slickPrev');
                     $(this).parents('.cont_slick').find('.slick_slider_no_home').slick('slickPrev');




                  });


                



                        /*

        $('.slick_slider_no_home').slick({
          dots: false,
          infinite: true,
          speed: 1000,
          slidesToShow: 1,
          centerMode: true,
          variableWidth: true,
          autoplay: true,
          autoplaySpeed: 2500,
          arrows:false,
          pauseOnFocus:true
        });


 

                          var swiperTemp = new Swiper('#'+idSwip, {
                            autoplay:2500,
                            speed:1000,
                            loop:true,  
                            disableOnInteraction:false,
                            nextButton: '.swiper-button-next',
                            prevButton: '.swiper-button-prev',    
                            centeredSlides: true,
                            slidesPerView: 1.5,
                            spaceBetween: 0,

                            breakpoints: {
                   
                              // when window width is <= 640px

                              
                             1024: {
                                slidesPerView: 1,
                                spaceBetween: 0
                              } 
                            }
                          });
                        */
                         // arrSwiper.push(swiperTemp);

                    });


  reSlickr();

 
                   AOS.init({
                    easing: 'ease-in-out-sine'
                  });
 

    		    });  		

 

			});

/*
            autoplay:2500,
            speed:1000,
            loop:true,  
            disableOnInteraction:false,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',    
            centeredSlides: true,
            slidesPerView: 1.5,
            spaceBetween: 0,

            breakpoints: {
   
              // when window width is <= 640px

              
             1024: {
                slidesPerView: 1,
                spaceBetween: 0
              } 
            }

**/

 
 
            jQuery('.row_bot_plus_proy').on("click",".mas_proyectos",function(ev){    
                ev.preventDefault();       

               // jQuery('.mas_proyectos').remove();

               var total = jQuery('#totalProyectos').data('total');

               

                jQuery('.img_loading').css('display','block'); 

                //console.log("CARGO PROYECTOS");

                //console.log(MyAjax.url+" "+MyAjax.nonce);

               // var elID = jQuery(this).data("id") ; 

                var desde  = jQuery('.i_proy').length;

                console.log("DESDE "+desde);
                              //


     
              jQuery.post(MyAjax.url, {nonce : MyAjax.nonce, action : 'cargarMasProyectos' ,desde : desde   }, function(response) {

                    //console.log("RESPONSE<br>");

                    //console.log(response);

                    //var nuResponse='<div class="proy_loaded"> '+response+'</div>';
                   // jQuery('.more_proys').append(response); 

                   var miResponse = jQuery(response);

                    if(jQuery(window).width()>668){

                    jQuery('.grid').append(miResponse).packery('appended', miResponse);  

                    console.log(response);
                    
                    reMasonry();


                  }else{

                    jQuery('.grid').append(miResponse);


                    jQuery('.item_proyecto_masonry').removeClass('item_proyecto_masonry');

                  }

                   // alert("!!!!");
                  //  jQuery('.grid')

                    reScroll();
                   // var bLazy = new Blazy();

                    jQuery('.img_loading').css('display','none');

                    var cargados = jQuery('.i_proy').length ;

                   // alert(total+" / "+cargados);

                    if(cargados>=total){
                      jQuery('.mas_proyectos').remove();
                    }


                 /*  AOS.init({
                    easing: 'ease-in-out-sine'
                  });*/
 

            });     

 

      });
 
 
            jQuery('.row_bot_plus_proy').on("click",".mas_noticias",function(ev){    
                ev.preventDefault();       

               // jQuery('.mas_proyectos').remove();

               var total = jQuery('#totalNews').data('total');

               

                jQuery('.img_loading').css('display','block'); 

                //console.log("CARGO PROYECTOS");

                //console.log(MyAjax.url+" "+MyAjax.nonce);

               // var elID = jQuery(this).data("id") ; 

                var desde  = jQuery('.item_new').length;
                var getTax =jQuery('#totalNews').data('tax');
                var catID= jQuery('#totalNews').data('term');

                  console.log("LOADEMORE DESDE "+desde+" " + getTax+" "+catID);
                             
     
              jQuery.post(MyAjax.url, {nonce : MyAjax.nonce, action : 'cargarMasNoticias' ,desde : desde, tax : getTax ,termID : catID   }, function(response) {

                    //console.log("RESPONSE<br>");

                    //console.log("ZERO " + response);

                    //console.log("////RESPONSE");

                    //var nuResponse='<div class="proy_loaded"> '+response+'</div>';

                    jQuery('.more_proys').append(response); 


                    reScroll();
                   // var bLazy = new Blazy();

                    jQuery('.img_loading').css('display','none');


                    var cargados = jQuery('.item_new').length ;

                   // alert(total+" / "+cargados);

                    if(cargados>=total){
                      jQuery('.mas_noticias').remove();
                    }

                    igualaAlturas(jQuery('.tit_new'));


                   AOS.init({
                    easing: 'ease-in-out-sine'
                  });
 

            });     

 

      });
	});