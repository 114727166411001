/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
var body = 'body,html';
var scrollTop;
var moreAlto = false;
var scene;
var sceneNews;

var siStart=false;

var direccion;

var msnry;



function reSlickr(){


  if(jQuery('.slick_slider_no_home').length>0){
     jQuery('.slick_slider_no_home img').height(jQuery(window).height()*0.65 );
  }

  //alert("RESLICK!");

}


function resize(){



      if(jQuery('.img_apoyado').length>0 ){

        jQuery('.img_apoyado').height(jQuery('.texto_apoyado').height() );

      }

}

function igualaAlturas(_itm){

  var alt=0;

  jQuery(_itm).each(function(){

    alt = Math.max(alt, jQuery(this).height() );


  });


  jQuery(_itm).height(alt);

}


function reNosotros(){

  jQuery('.apoyado').height(0);

 // jQuery('.t_apoyado').width(jQuery('.texto_apoyado').outerWidth(true));

  jQuery('.apoyado').height(jQuery('.texto_apoyado').outerHeight(true));





}


function reScroll(){

  //alert("RESCROLL!!!");

  if(jQuery('body').outerHeight(true)>jQuery(window).outerHeight(true) ){





  // alert(  jQuery('body').outerHeight(true)+" / "+jQuery(window).outerHeight(true)   );

  if(!moreAlto){

  console.log("RESCROLL" +  jQuery('body').outerHeight(true)+" / "+jQuery(window).outerHeight(true)   );




       moreAlto = true;

      }


  }else{

    if(moreAlto){



      moreAlto = false;
    }


  }

}


function reMasonry(){

  if(jQuery(window).width()>668){

    console.log("REMASONRY");

      jQuery('.bloque_2v').each(function(i){

        jQuery(this).height(jQuery(this).width() *2 );
      });

      jQuery('.bloque_4').each(function(i){

        jQuery(this).height(jQuery(this).width() );
      });

      jQuery('.bloque_2h').each(function(i){

        jQuery(this).height(jQuery(this).width()/2 );
      });

      jQuery('.bloque_1').each(function(i){

        jQuery(this).height(jQuery(this).width() );
      });


      jQuery('.grid').packery();

  }




}

function reMenu(){


  if(jQuery(window).outerWidth() >= 1024){


        var anchMenu = 0;
        var anchRowMenu = jQuery('ul.nav').outerWidth(true) ;
        var difAncho;
        var sepMenu;

        //alert(anchRowMenu);

        jQuery('ul.nav li').each(function(){

          anchMenu+=jQuery(this).outerWidth();

        });

        //alert(anchMenu+" / " +anchRowMenu);
        difAncho = anchRowMenu - anchMenu;
       // alert(difAncho);
        sepMenu = Math.floor(difAncho / (jQuery('ul.nav li').length -1) );// -5;
      //  alert(sepMenu);

        for(var u=0; u<jQuery('ul.nav li').length - 1 ; u++){
          jQuery('ul.nav li').eq(u).css('margin-right', sepMenu);
        }

  }else{

    jQuery('.menu_peq').slideUp(1, function(){});


  }

//  alert("REMEU");


}

function reHome(){


  if(jQuery(window).width()>768){
      //var winAlt =  jQuery(window).outerHeight(true) - jQuery('.banner').outerHeight(true) - jQuery('.avance').outerHeight(true);
      var winAlt =  jQuery(window).outerHeight(true) - jQuery('.banner').outerHeight(true) ;
      //alert(jQuery('.banner').outerHeight(true) );
      //jQuery('.swiper-home .swiper-slide').outerHeight(winAlt);
      jQuery('.swiper-home .slick-slide').outerHeight(winAlt);
     //alert(winAlt);

  }


}


function slideOp(_itm){

    if(_itm.css('opacity')==1){
      _itm.css('opacity', 0);
    }else{

      _itm.css('opacity', 1);

    }

}


function reProducto(){



  if(jQuery('.swiper_rescale').length>=1){

    var altMax=0;



    if(jQuery('.img_firma').length>=1){

       altMax= jQuery('.img_firma').offset().top + jQuery('.img_firma').outerHeight(true) + parseInt(jQuery('.img_firma').css('margin-top')) + parseInt(jQuery('.img_firma').css('margin-bottom'))  ;

      var alto = jQuery(window).height() - altMax;

      //console.log("ALTO "+alto);

      jQuery('.swiper_rescale').height(alto);

      jQuery('.swiper_rescale .swiper-slide').height(alto);


    }else{



    }



   // alert(altMax);



  }



}


(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.





  function cambioPagina(_url){


    $('.sobre_capa_trans').animate({'opacity':1}, 400, function(){

      window.location.href = _url;

    });


  }

  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages



        $('.menu_amp').on('click', function(ev){

          ev.preventDefault();

          $('.bot_open_menu').toggleClass('h_open');
         //alert("!!!");
          $('.menu_amp').toggleClass('amp_abierto');

          $('.main').toggleClass('blurred');

        });


// .cerrar_menu
        $('.bot_open_menu').on('click', function(ev){

          ev.preventDefault();

          $(this).toggleClass('h_open');
         //alert("!!!");

          $('.menu_amp').toggleClass('amp_abierto');

          $('.main').toggleClass('blurred');

        });

        $('.amp_abierto').on('click', function(ev){

          $('.amp_abierto').removeClass('amp_abierto');

        });




          //NEWSLETTER

          $('.bot_newsletter ').on('click', function(ev){
            ev.preventDefault();

           // console.log("CLICK! NEWSLETTER");

            slideOp($('.claim_home'));


            $('.news_foo').slideToggle(400, function(){

              console.log("SLIDE! NEWSLETTER");

               TweenLite.to(window, 1.5, {ease:Linear.easeIn ,scrollTo:( $('.news_foo').outerHeight(true) +  $('.news_foo').offset().top ) });

            });

          });


          $('  .cerrar_news').on('click', function(ev){

             slideOp($('.claim_home'));
            ev.preventDefault();
            $('.news_foo').slideToggle(400, function(){

               TweenLite.to(window, 1.5, {scrollTo:( $('footer')   ) });

            });

          });




        $('a').on('click', function(ev){



          if( !$(this).hasClass('nolink')  && !$(this).hasClass('share')   ){



                  ev.preventDefault();
                  var miURL = $(this).attr('href');
                  cambioPagina(miURL);

          }


        });



      $(window).on('scroll', function(ev){

          scrollTop = $(window).scrollTop();


          if(scrollTop > $('.banner').outerHeight(true) ){

          //  $('.banner').addClass('minibar');

          $('.banner').addClass('minibar_nu');

          }else{

          //  $('.banner').removeClass('minibar');

          $('.banner').removeClass('minibar_nu');

          }


      });


      $(window).resize(function(){

        reMenu();
        reScroll();

        reProducto();
        reNosotros();


        reSlickr();


      if(jQuery('body').hasClass('showroom')){


        jQuery('.cont_showroom h4').css('height','auto');
        jQuery('.cont_showroom .p_show').css('height','auto');

        igualaAlturas(jQuery('.cont_showroom h4') );
         igualaAlturas(jQuery('.cont_showroom .p_show') );

      }




      });


      $('.menu_peq').slideUp(1, function(){});


      $('.bot_menu_peq').on('click', function(){

          $('.menu_peq').slideToggle(400, function(){

            });

      });


     //  var bLazy = new Blazy();


        jQuery('html').scrollWithEase();



        $('html, body').animate( { scrollTop : 0 }, 0 );



        //NEWSLETTER

        $('#lista').change(function(){

         // alert($('#mc-embedded-subscribe-form').attr('action'));
         // alert($(this).val());

          $('#mc-embedded-subscribe-form').attr('action', $(this).val()) ;

         // alert($('#mc-embedded-subscribe-form').attr('action'));


        });

//https://ffwagency.com/digital-strategies-blog/whats-difference-between-atomic-design-and-pattern-libraries




  //PRELOAD

  if(arrImgCargar.length>0){

      (function($) {
        var imgList = [];
        $.extend({
          preload: function(imgArr, option) {
            var setting = $.extend({
              init: function(loaded, total) {},
              loaded: function(img, loaded, total) {},
              loaded_all: function(loaded, total) {}
            }, option);
            var total = imgArr.length;
            var loaded = 0;
            setting.init(0, total);
            for(var i in imgArr) {
              imgList.push($("<img />")
                .attr("src", imgArr[i])
                .load(function() {
                  loaded++;
                  setting.loaded(this, loaded, total);
                  if(loaded == total) {
                    setting.loaded_all(loaded, total);
                  }
                })
              );
            }
          }
        });
          $.preload(arrImgCargar
           ,
          {
              init: function(loaded, total) {
             // $('body').html("Loading "+loaded+" of "+total+" images");
              console.log("Loading "+loaded+" of "+total+" images");
              },
              loaded: function(img, loaded, total) {
               //   $('body').html("Loading  "+loaded+" of "+total+" images");
               console.log("LOADED  "+loaded+" of "+total+" images");


               if(loaded==Math.floor(total/2)){

                  jQuery('.sobre_capa_anime').animate({'opacity':0}, 400, function(){

                      jQuery('.sobre_capa_trans').animate({'opacity':0}, 400, function(){  });

                  });

               }

              },
              loaded_all: function(loaded, total) {
                //  $('body').html("&#9786;  all loaded").addClass('loaded');
                console.log("LOADED  "+loaded+" of "+total+" images");


              //  alert("CARGA!");
              /*
                jQuery('.sobre_capa_anime').animate({'opacity':0}, 400, function(){

                    jQuery('.sobre_capa_trans').animate({'opacity':0}, 400, function(){  });

                });
              */


              }
          });
      })(jQuery);




  }


//LAZYLOAD

      var myLazyLoad = new LazyLoad();




      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },

    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        $('.desc_home li').each(function(i){


          //$(this).delay((i*100)+ 1500).addClass('aparece_li');//

           $(this).delay((i*300)+ 1500).animate(
            {
            'left':0,
            'opacity':1

            }
            , 500 ,"easeOutSine" , function(){
            //  $(this).addClass('aparece_li');
            });


        });

      $(window).resize(function(){

        reHome();

      });


      $('.avance').on('click', function(){

        TweenLite.to(window, 0.75, {scrollTo:($('#slider_home').outerHeight(true) + $('#slider_home').offset().top) });

      });

//alert("!!!!!");

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'single_proyecto': {
      init: function() {
        // JavaScript to be fired on the about us page

        $('.menu_proyectos').addClass('current-menu-item');


      }
    },
    // About us page, note the change from about-us to about_us.
    'single_producto': {
      init: function() {
        // JavaScript to be fired on the about us page

        $('.menu_producto').addClass('current-menu-item');

        //swiper-single-producto



      }
    },

    'single_post':{

      init:function(){

        $('.entry-content img').each(function(){

          if($(this).parent().attr('href')!=""){

            $(this).parent().attr('data-fancybox','group');

             $(this).parent().addClass('nolink');
          }

        });

      $("[data-fancybox]").fancybox({
        // Options will go here
      });

      $('.post_content p').each(function(){

        var soyIMG = $(this).html() ;
        console.log( soyIMG.indexOf('<img') );
        if(soyIMG.indexOf('<img')==-1  ){
          $(this).addClass('solo_p_texto');

        }



      });

      }

    },


    // About us page, note the change from about-us to about_us.
    'post_template_default': {
      init: function() {
        // JavaScript to be fired on the about us page



      $('article img').each(function(i){

        if($(this).parent('p') ){

         $(this).parent('p').addClass('anchocien');
        }

      });

      }
    },


    'page_template_template_showroom': {
      init: function() {
        // JavaScript to be fired on the about us page



       // igualaAlturas(jQuery('.cont_showroom h4') );
       // igualaAlturas(jQuery('.cont_showroom p') );




        /*
          var prop = 1280/768;
          var alto = $(window).width()/2 - 30;
          alto /= prop;

          $('.swiper-single-showroom').css('height', alto  );

  */

          if( $(window).width()<=768 ){

            $('.texto_showroom .more_projects').parent('p').css('text-align','center');



          /* $('.texto_showroom p').each( function(i){

              if( $(this).find('a.more_projects') ){
                $(this).css('text-align','center');
              }

           });*/

         }


      }
    },



    'page_template_template_nosotros_foto_gran': {
      init: function() {
        // JavaScript to be fired on the about us page




          reNosotros();




      }
    },



    'page_template_template_proyectos': {
      init: function() {
        // JavaScript to be fired on the about us page

        var totalProy =  jQuery('#totalProyectos').data('total')  ;

        if(totalProy<=jQuery('.i_proy').length){

          jQuery('.row_bot_plus_proy').remove();

        }

       // igualaAlturas(jQuery('.cont_showroom h4') );
       // igualaAlturas(jQuery('.cont_showroom p') );


      }
    },








    // About us page, note the change from about-us to about_us.
    'tax_tipo_prod': {
      init: function() {
        // JavaScript to be fired on the about us page


        $('.menu_producto').addClass('current-menu-item');



        $('.listado_ambitos li a').on('click', function(ev){

        ev.preventDefault();

            var miItem = $(this).attr('href');
            console.log(miItem);

            $('.item_proyecto').removeClass('desactivada');

          $('.desactivada').removeClass('desactivada');

          if($(this).hasClass('activo') ){

            $(this).removeClass('activo');




          }else{

            $('.activo').removeClass('activo');

            $(this).addClass('activo');

            //$('.'+miItem).addClass('desactivada');




            $('.item_proyecto').each(function(){

              if( !$(this).hasClass(miItem) ){
                $(this).addClass('desactivada');
              }

            });


          }

        });


      }
    }
  };




  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

window.onload=function(){



      if(jQuery('body').hasClass('showroom')){

          var t= window.setInterval(function(){

            console.log("HOLA " + jQuery('.cont_showroom h4').height() );

            if(jQuery('.cont_showroom h4').height()>5){

              igualaAlturas(jQuery('.cont_showroom h4') );
              clearInterval(t);


            }

          },100);

         // alert(  jQuery('.p_show').height() );

          var t2= window.setInterval(function(){

           console.log( jQuery('.cont_showroom .p_show').height() );

              if(jQuery('.cont_showroom .p_show').height()>5){

                igualaAlturas(jQuery('.cont_showroom .p_show') );
                clearInterval(t2);


              }
            },100);




      }




    jQuery('.wrap').css('display', 'block');

    if(jQuery('.bot_menu_peq').css('display')=='none' ){

        jQuery('.wrap').css('margin-top', jQuery('.banner').height() );

    }else{

      console.log(jQuery('.submenu').offset().top + jQuery('.submenu').height());

        jQuery('.wrap').css('margin-top', jQuery('.bot_menu_peq').offset().top + jQuery('.bot_menu_peq').height() + 25 );

    }




    if( arrImgCargar.length==0){

        jQuery('.sobre_capa_anime').animate({'opacity':0}, 400, function(){

            jQuery('.sobre_capa_trans').animate({'opacity':0}, 400, function(){  });

        });

      }





/*
    reMenu();

    reHome();

    reScroll();

    reNosotros();

    reProducto();

    reSlickr();

*/



if(jQuery('.header_post')){

      jQuery('.header_post').width( jQuery('.header_w').width() );

}


 /*

      var swiperSingleProd = new Swiper('.swiper-single-producto', {
          slidesPerView: 1,
          autoplay:2500,
          speed:1000,
          loop:true,
          nextButton: '.swiper-button-next',
          prevButton: '.swiper-button-prev'
      });
*/

//

reSlickr();


$('.slick_slider_home').slick({
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  autoplay: true,
  autoplaySpeed: 2500,
  arrows:false ,
  touchMode:false,
  draggable:false,
   pauseOnFocus:false,
   pauseOnHover:false
});



$('.slick_slider_no_home').slick({

  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  variableWidth: true,
  autoplay: true,
  autoplaySpeed: 2500,
  arrows:false,
   pauseOnFocus:false,
   pauseOnHover:false
});


$('.slick_slider_gallery').slick({
  speed: 300,
  slidesToShow: 3,
  dots: true,
  centerMode: true,
  variableWidth: true,
  infinite:true,
  draggable:true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,

      }
    }
  ]

});

/*
  centerMode: true,
  variableWidth: true,
  autoplay: true,
  autoplaySpeed: 2500,
  arrows:false,
   pauseOnFocus:true,
   pauseOnHover:true
*/


/*
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 3,
  centerMode: true,
  variableWidth: true,
  autoplay: true,
  autoplaySpeed: 2500,
  arrows:false,
  pauseOnFocus:false ,

  onInit: function() {

            console.log('slider was initialized');
        }
*/


$('.slick_slider_prod_home').slick({
    dots: false,
    infinite: true,
    speed: 1000,
    centerMode: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnFocus:false,

 responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    } ,
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }

    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]




});





    jQuery('.slick_der').on('click', function(ev){
      ev.preventDefault();
      $(this).parents('.cont_slick').find('.slick_slider').slick('slickNext');
      console.log("CLICK!!!");

    });

    jQuery('.slick_left').on('click', function(ev){

      ev.preventDefault();
      $(this).parents('.cont_slick').find('.slick_slider').slick('slickPrev');
      console.log("CLICK!!!");


    });



  if($('.swiper-container').hasClass('swiper-single-showroom') ){


    /*

        var swiperShowroom = new Swiper('.swiper-single-showroom', {
           // autoHeight:true,
            autoplay:2500,
            speed:1000,
            loop:true,
            disableOnInteraction:false,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',

            centeredSlides: true,
                slidesPerView: 1.5,
                spaceBetween: 0,

            breakpoints: {

              // when window width is <= 640px


             1024: {
                slidesPerView: 1,
                spaceBetween: 0
              }
            }



        });

*/

      // alert(jQuery('.swiper-single-showroom .swiper-slide img').height() );

      if(jQuery('body').hasClass('single-producto') || jQuery('body').hasClass('single-proyecto') || jQuery('body').hasClass('page-template-template-showroom') ){
         jQuery('.swiper-single-showroom').height(jQuery('.swiper-single-showroom .swiper-slide img').height()  );
         jQuery('.swiper-single-showroom .swiper-slide').height(jQuery('.swiper-single-showroom .swiper-slide img').height()  );

     //   jQuery('.swiper-single-showroom .swiper-slide img').height(400 );
     // jQuery('.swiper-single-showroom .swiper-slide img').css('width', 'auto !important' );
     // jQuery('.swiper-single-showroom .swiper-slide img').css('max-width', 'auto !important' );

    //  alert(jQuery('.swiper-single-showroom .swiper-slide img').css('width')   )

      }







  }




      if(jQuery('window').width() >= 768){

        igualaAlturas(jQuery('.tit_new'));
      }else{

      }


       jQuery('.resto_home').css('margin-top', jQuery('.franja_principal').height() );

       jQuery('.todo_medida').height(  jQuery('.resto_home').height()   ) ;


      // SCROLLMAGIC

      if(  jQuery('body').hasClass('home')  ){

        /*


      var swiperHome = new Swiper('.swiper-home', {
          autoplay:2000,
          speed:1000,
          loop:true,
          effect:'fade',
          fade: {
            crossFade: false
          }
      });


      */

 /*

      var swiperHomeProd =new Swiper('.swiper-prod-home', {
        slidesPerView: 3,
        autoplay:1500,
        spaceBetween: 15,
        speed:1000,
        loop:true,
        nextButton: '.swiper-prod-home .swiper-button-next',
        prevButton: '.swiper-prod-home .swiper-button-prev' ,


          breakpoints: {

            // when window width is <= 640px


           640: {
              slidesPerView: 1,
              spaceBetween: 0
            }
          }


      });
*/


jQuery('.swiper-prod-home .swiper-button-next, .swiper-prod-home .swiper-button-prev').removeClass('swiper-button-disabled');



      // init controller
      var controller = new ScrollMagic.Controller();
      var controllerFooter = new ScrollMagic.Controller();

      var medidaTop = $('#medida_home').offset() ;
      medidaTop = medidaTop.top;

      var offSetStart = $('#slider_home').height()/2;

      var direccionFoo;

      /*
              scene.triggerHook(0);
              scene.update();
      */

        scene = new ScrollMagic.Scene({triggerElement: "#slider_home" ,duration: medidaTop  })

               // .addIndicators()  //add indicators (requires plugin)
                .offset(offSetStart)

                .on("update", function (e) {
                  //$("#scrollDirection").text(e.target.controller().info("scrollDirection"));
                  //console.log(e.target.controller().info("scrollDirection"));

                  direccion =  e.target.controller().info("scrollDirection");

                })
                .on("enter leave", function (e) {
                 // $("#state").text(e.type == "enter" ? "inside" : "outside");
                 console.log("ENTER LEAVE " + e.type);
                })

                .on("progress", function (e) {
                 // $("#progress").text(e.progress.toFixed(3));
                // console.log("PROGRESS "+ e );



                });
                scene.on("add", function (event) {
                    console.log('Scene was added to a new controller.');
                });
                scene.addTo(controller);




         scene.triggerHook(0);


         var medidaFooter = $('footer').height()*1;
         var timeOpen;

         var solo1=false;

         sceneNews = new ScrollMagic.Scene({triggerElement: "#finFooter" ,duration: medidaFooter  })
        // .addIndicators()
               .on("update", function (e) {
                  //$("#scrollDirection").text(e.target.controller().info("scrollDirection"));
                  //console.log(e.target.controller().info("scrollDirection"));

                  direccionFoo =  e.target.controller().info("scrollDirection");

                })
                .on("enter", function (e) {
                 // $("#state").text(e.type == "enter" ? "inside" : "outside");

                 //alert("FOOTER END");
                // $('.bot_newsletter').trigger( "click" );

                if(!solo1){

                      if(direccionFoo=="FORWARD"){
                        console.log("ABRO FOOTER "  + e.type + " " + direccionFoo);
                        timeOpen = setTimeout(function(){
                         // jQuery('.bot_newsletter ').trigger('click');


                          slideOp($('.claim_home'));


                          $('.news_foo').slideDown(400, function(){

                            console.log("SLIDE! NEWSLETTER");

                             TweenLite.to(window, 1.5, {ease:Linear.easeIn ,scrollTo:( $('.news_foo').outerHeight(true) +  $('.news_foo').offset().top ) });

                          });

                          //console.log(solo1);

                        }, 300);
                        solo1 = true;

                        console.log(solo1);



                      }else{
                        console.log("CIERRO FOOTER "  + e.type + " " + direccionFoo);
                      //   jQuery('.cerrar_news ').trigger('click');
                     // jQuery('.bot_newsletter ').trigger('click');
                      }



                  }


                 //alert("!!!!CLICK");
                });



         sceneNews.addTo(controllerFooter);
         sceneNews.triggerHook(1);

         function setBlur(){


         }


         function sacoTextoHome(){

         console.log("!!!!SACO");
            jQuery('.claim_home li').each(function(i){

                console.log("I "+i);

                jQuery(this).delay(300*i).animate({
                    'margin-top':'10px',
                    'margin-bottom':'10px'
                } ,{


                  duration:0,
                   done:function(){
                    jQuery(this).addClass('noBlur');
                   }


                }



                )
            });
         }


         jQuery(window).scroll(function(){


          if(!siStart){

              scene.on("start end", function (e) {
                $("#lastHit").text(e.type == "start" ? "top" : "bottom");
                console.log("START / END "+ e.type + " " +scene.triggerPosition());

                if(e.type=="start"  ){

                    if(direccion=="FORWARD"){
                      console.log("FORWARD!!!!!");
                      $('#medida_home').addClass('disp_none');
                      $('.resto_home').addClass('reset_resto');



                      sacoTextoHome();

                      AOS.refresh();



                    } else{
                      console.log("REVERSE!!!!");
                    $('#medida_home').removeClass('disp_none');
                      $('.resto_home').removeClass('reset_resto');
                    }


                     $('.claim_home').css('opacity', 1);

                }


              });

              siStart =true;
          }

         // console.log(jQuery('.resto_home').offset() );




         });


      }  //IF HOME


//

    if(jQuery(window).width()>668){

        jQuery('.bloque_2v').each(function(i){

        jQuery(this).height(jQuery(this).width() *2 );
      });

      jQuery('.bloque_4').each(function(i){

        jQuery(this).height(jQuery(this).width() );
      });

      jQuery('.bloque_2h').each(function(i){

        jQuery(this).height(jQuery(this).width()/2 );
      });

      jQuery('.bloque_1').each(function(i){

        jQuery(this).height(jQuery(this).width() );
      });


      msnry = jQuery('.grid').packery({
      // options
      itemSelector: '.grid-item'
      });


      jQuery('.grid').packery();


    }else{
      jQuery('.grid-item').removeClass('grid-item');
      jQuery('.item_proyecto_masonry').removeClass('item_proyecto_masonry');



    }





    if(jQuery('body').hasClass('blog')){



    igualaAlturas(jQuery('.back_desta') );

    igualaAlturas(jQuery('.tit_new_desta'));

     jQuery('.marco_line').each(function(i){

      var bot = jQuery(this).parents('.item_new_desta').find('.tit_new_desta').outerHeight();


     });


    }


    if( jQuery(window).width()>768 ){

          AOS.init({
              easing: 'ease-in-out-sine'
        });

    }else{
        jQuery('.aos-item').removeAttr('data-aos').removeClass('aos-item');
      //  jQuery('.aos-item');
    }


        //.force_carga

        jQuery('.force_carga').each(function(index){

          var miSRC = jQuery(this).find('img').attr('data-src');
          jQuery(this).find('img').attr('src' , miSRC);
          jQuery(this).addClass('img_auto');
          jQuery(this).find('img').addClass('img_resp');

          console.log('miSRC');

          console.log(miSRC);



        })



    reMenu();

    reHome();

    reScroll();

    reNosotros();

    reProducto();

    reSlickr();

    resize();




}


